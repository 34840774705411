import React, { useEffect, useState } from "react";
import { Button, Collapse, Card } from "react-bootstrap";
import CustomTable from "./customTable";
import { EditableModal } from "./editableModal";
import { CREATE_EXPENSE_CONFIGS } from "../configs/createNovelConfig";
import ExpenseTrackingMetadataDB from "../storage/ExpenseTrackingMetadataDB";
import { toast } from "react-toastify";
import { INPUT_TYPE } from "../constants";
import { getLocalStorageItem } from "../utils/helperFunctions";

export default function ExpenseList(props) {
  const { items = [], defaultOpen = true, paginationSize = 20, loadItems = () => {}, onlineItems = [] } = props;
  const [open, setOpen] = useState(defaultOpen);

  const [customCategory, setCustomCategory] = useState([]);
  useEffect(() => {
    const { customCategory: c2 = [] } = getLocalStorageItem("customCategory") || {};
    setCustomCategory(c2);
  }, []);
  const catEnum = [
    "Grocery",
    "Transport",
    "Utility",
    "Online Shopping",
    "Clothes",
    "Entertainment",
    "Daily",
    "Other",
    "Drinks",
    "Restaurant",
    "Social",
    ...customCategory,
  ].map((item) => ({
    name: item,
    value: item,
  }));
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="expense-table"
        aria-expanded={open}
        variant="primary"
        style={{ marginBottom: "1rem" }}
      >
        {open ? "Hide Expenses Details" : "Show Expenses Details"}
      </Button>
      <Collapse in={open}>
        <div id="expense-table">
          <Card>
            <Card.Body>
              <CustomTable
                headers={["Title", "Category", "Sub Category", "Amount", "purchasedDate", "Update"]}
                colCanSort={[true, true, true, true]}
                paginationSize={paginationSize}
                rows={items.map((item) => {
                  const { id } = item;
                  const savedOnlined = onlineItems.find((onlineItem) => onlineItem.id === id);
                  return [
                    { value: item.title },
                    { value: item.category },
                    { value: item.subCategory },
                    { value: `$ ${item.amount || 0}` },
                    { value: item.purchasedDate },
                    {
                      value: (
                        <EditableModal
                          title={`Update expense: ${savedOnlined ? "Saved" : "Not Save Online"}`}
                          configs={[
                            ...CREATE_EXPENSE_CONFIGS,
                            {
                              fieldName: "category",
                              label: "category",
                              enumValues: catEnum,
                              required: true,
                              inputType: INPUT_TYPE.SELECT,
                            },
                            {
                              fieldName: "subCategory",
                              label: "subCategory",
                              enumValues: catEnum,
                              required: false,
                              inputType: INPUT_TYPE.SELECT,
                            },
                          ].filter((item) => item.fieldName !== "bulkAddItemStr")}
                          actionLabel={"Update"}
                          currentValue={item}
                          actionOnClick={async (data) => {
                            const updatedItem = { ...item, ...data, lastUpdatedDate: new Date().toISOString() };
                            await ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(updatedItem);
                            toast.info(`Successfully updated ${updatedItem.title}`, updatedItem);
                            loadItems();
                          }}
                        />
                      ),
                    },
                  ];
                })}
              />
            </Card.Body>
          </Card>
        </div>
      </Collapse>
    </div>
  );
}
