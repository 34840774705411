import { INPUT_TYPE } from "../constants";

export const CREATE_MOVIE_CONFIGS = [
  {
    fieldName: "title",
    label: "title",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "description",
    label: "description",
    inputType: INPUT_TYPE.TEXT_AREA,
  },
  {
    fieldName: "type",
    label: "type",
    enumValues: ["", "动漫", "电影", "儿童", "电视剧"],
    inputType: INPUT_TYPE.SELECT,
  },
];

export const CREATE_EXPENSE_CONFIGS = [
  {
    fieldName: "title",
    label: "title or you can use bulk add",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "description",
    label: "description",
    inputType: INPUT_TYPE.TEXT_AREA,
  },
  {
    fieldName: "amount",
    label: "Purchased Amout",
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    fieldName: "bulkAddItemStr",
    label: "Bulk Add item: enter <title>,<amount>",
    inputType: INPUT_TYPE.TEXT_AREA,
  },
  {
    fieldName: "purchasedDate",
    label: "Purchased Date",
    inputType: INPUT_TYPE.DATE,
    required: true,
  },
];
